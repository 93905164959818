<template>
    <b-modal
        v-model="config.isShowModal"
        size="xl"
        no-close-on-backdrop
        :id="config.codeWS + '-modal'"
        :title="$t('sheduleFile.detailWork')"
        scrollable
        hide-footer
        @hidden="handleCloseModal"
    >
        <div style="height: 450px; overflow-y: auto">
            <table class="table mb-0 table-bordered">
                <thead>
                    <tr>
                        <th rowspan="1" colspan="9" class="font-weight-bold">
                            <div style="word-wrap: break-word; font-size: 16px" class="col-sm-10 p-0">
                                {{ config.detailTitle }}
                            </div>
                        </th>
                    </tr>
                    <tr>
                        <th>
                            <div style="min-width: fit-content; white-space: nowrap">
                                {{ $t('sheduleFile.orderID') }}
                            </div>
                        </th>

                        <th>
                            <div style="min-width: fit-content; white-space: nowrap">
                                {{ $t('sheduleFile.items') }} <span class="text-danger">*</span>
                            </div>
                        </th>

                        <th>
                            <div style="min-width: fit-content; white-space: nowrap">
                                {{ $t('sheduleFile.referenceDate') }}
                            </div>
                        </th>

                        <th>
                            <div style="min-width: fit-content; white-space: nowrap">
                                {{ $t('sheduleFile.daysFromBaseDate') }}
                            </div>
                        </th>

                        <th>
                            <div style="min-width: fit-content; white-space: nowrap">
                                {{ $t('sheduleFile.columnToReflect') }}
                            </div>
                        </th>

                        <th></th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(item, idx) in arrayObject" :key="idx">
                        <td>
                            {{ idx + 1 }}
                        </td>

                        <td>
                            <input
                                id="validationCustom01"
                                v-model="item.detail"
                                type="text"
                                class="form-control"
                                :class="{
                                    'is-invalid': item.errorDetail
                                }"
                                @blur="handleValidate('detail', idx)"
                            />
                            <div v-if="item.errorDetail" class="invalid-feedback">
                                <span>{{ item.errorDetail }}</span>
                            </div>
                        </td>

                        <td>
                            <div style="width: 150px">
                                <Multiselect
                                    :id="`${idx}-multi-select-date-type-id`"
                                    :value.sync="item.date_type_id"
                                    :options="date_previous"
                                    :config.sync="item.configDateTypeId"
                                />
                            </div>
                        </td>

                        <td>
                            <input
                                id="validationCustom02"
                                v-model.number="item.calculation_date"
                                type="number"
                                class="form-control"
                                :class="{
                                    'is-invalid': item.errorCalculationDate
                                }"
                                @blur="handleValidate('calculation_date', idx)"
                            />
                            <div v-if="item.errorCalculationDate" class="invalid-feedback">
                                <span>{{ item.errorCalculationDate }}</span>
                            </div>
                        </td>

                        <td>
                            <div style="width: 120px">
                                <Multiselect
                                    :id="`${idx}-multi-select-place-data-export-id`"
                                    :value.sync="item.place_data_export_id"
                                    :options="feedback"
                                    :config.sync="item.configPlaceDataExportId"
                                />
                            </div>
                        </td>

                        <td>
                            <button class="btn btn-info" style="min-width: fit-content" @click="addInput(idx)">
                                <i class="fa fa-plus"></i>
                            </button>
                        </td>

                        <td>
                            <button
                                class="btn btn-danger"
                                style="min-width: fit-content"
                                :disabled="arrayObject.length <= 1"
                                @click="removeInput(idx)"
                            >
                                <i class="fa fa-minus"></i>
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

        <div class="d-flex justify-content-center mt-5">
            <div class="text-center flex-fill">
                <button type="button" class="btn btn-light btn-sm mr-3" @click="handleCloseModal">
                    {{ $t('btn.cancel') }}
                </button>
                <button type="button" class="btn btn-primary ml-3" @click="handleSave">
                    {{ $t('btn.save') }}
                </button>
            </div>
        </div>
    </b-modal>
</template>

<script>
import i18n from '@/i18n';
import Multiselect from '@/components/Multiselect/main.vue';

import { DATE_PREVIOUS, FEEDBACK } from '@/views/pages/masters/schedule/constants';
export default {
    name: 'PopUpDetailWorkspace',
    components: {
        Multiselect
    },
    props: {
        config: {
            type: Object,
            required: false,
            default: () => ({})
        }
    },
    data() {
        return {
            date_previous: DATE_PREVIOUS,
            feedback: FEEDBACK,
            arrayObject: [this.initLogObject()],
            currentId: null
        };
    },
    methods: {
        initLogObject(data = null) {
            let object = {
                id: '',
                order_id: 1,
                detail: '',
                errorDetail: '',
                date_type_id: null,
                configDateTypeId: {
                    trackBy: 'value',
                    label: 'label',
                    error: false
                },
                calculation_date: '',
                errorCalculationDate: '',
                place_data_export_id: null,
                configPlaceDataExportId: {
                    trackBy: 'value',
                    label: 'label',
                    error: false
                }
            };

            if (!data) {
                object = {
                    ...object
                };
            } else {
                object = {
                    ...object,
                    id: data?.id,
                    order_id: data?.order_id,
                    detail: data?.detail,
                    date_type_id: this.date_previous.find((x) => x.value === data?.date_type_id),
                    calculation_date: data?.calculation_date,
                    place_data_export_id: this.feedback.find((x) => x.value === data?.place_data_export_id)
                };
            }
            return object;
        },

        addInput(idx) {
            this.arrayObject.splice(idx + 1, 0, this.initLogObject());
        },

        removeInput(idx) {
            this.arrayObject.splice(idx, 1);
        },

        handleCloseModal() {
            setTimeout(() => {
                this.handleRenderCommon(this.$props.config.dataWorks);
                this.$emit('update:validateDetail', true);
                this.config.isShowModal = false;
            }, 250);
        },

        handleValidate(field, index) {
            let check = true;
            let min = -5000;
            let max = 5000;
            this.arrayObject.forEach((item, key) => {
                if (!field) {
                    if (!item.detail) {
                        item.errorDetail = i18n.t('validateField.required', {
                            field: i18n.t('sheduleFile.items')
                        });
                        check = false;
                    } else {
                        if (item.detail.length >= 255) {
                            item.errorDetail = i18n.t('validateField.max255', {
                                field: i18n.t('sheduleFile.items')
                            });
                            check = false;
                        } else {
                            item.errorDetail = '';
                        }
                    }

                    if (item.calculation_date < min || item.calculation_date > max) {
                        (item.errorCalculationDate = i18n.t('sheduleFile.limit+-5000Date')), (check = false);
                    } else {
                        item.errorCalculationDate = '';
                    }
                } else {
                    if (index !== key) return;
                    if (field === 'detail') {
                        if (!item.detail) {
                            item.errorDetail = i18n.t('validateField.required', {
                                field: i18n.t('sheduleFile.items')
                            });
                            check = false;
                        } else {
                            if (item.detail.length >= 255) {
                                item.errorDetail = i18n.t('validateField.max255', {
                                    field: i18n.t('sheduleFile.items')
                                });
                                check = false;
                            } else {
                                item.errorDetail = '';
                            }
                        }
                    }

                    if (field === 'calculation_date') {
                        if (item.calculation_date < min || item.calculation_date > max) {
                            item.errorCalculationDate = i18n.t('sheduleFile.limit+-5000Date');
                            check = false;
                        } else {
                            item.errorCalculationDate = '';
                        }
                    }
                }
            });

            this.$emit('update:validateDetail', check);
            return check;
        },

        handleSave() {
            let check = this.handleValidate();
            if (!check) return;
            let mapData = this.arrayObject.map((item, idx) => {
                item.order_id = idx + 1;
                item.date_type_id = item.date_type_id?.value ?? '';
                item.place_data_export_id = item.place_data_export_id?.value ?? '';
                if (!item.id) delete item.id;
                return {
                    calculation_date: item.calculation_date,
                    date_type_id: item.date_type_id,
                    detail: item.detail,
                    id: item.id,
                    order_id: item.order_id,
                    place_data_export_id: item.place_data_export_id
                };
            });
            this.$emit('update:data', {
                code: this.config.codeWS,
                data: mapData
            });
            this.handleCloseModal();
        },

        handleRenderCommon(dataBegin) {
            this.arrayObject = [this.initLogObject()];
            if (dataBegin) {
                dataBegin.forEach((item, key) => {
                    if (key === 0) {
                        this.arrayObject[0].id = item?.id;
                        this.arrayObject[0].order_id = item?.order_id;
                        this.arrayObject[0].detail = item?.detail;
                        this.arrayObject[0].date_type_id = this.date_previous.find((x) => x.value === item?.date_type_id) ?? '';
                        this.arrayObject[0].calculation_date = item?.calculation_date;
                        this.arrayObject[0].place_data_export_id = this.feedback.find((x) => x.value === item?.place_data_export_id) ?? '';
                    } else {
                        this.arrayObject.push(this.initLogObject(item));
                    }
                });
            }
        }
    },
    watch: {
        'config.codeWS': function (value) {
            if (value !== this.currentId) {
                this.arrayObject = [this.initLogObject()];
                this.currentId = value;
            }
        },
        'config.dataWorks': function (dataBegin) {
            this.handleRenderCommon(dataBegin);
        }
    }
};
</script>

<style lang="scss" scoped></style>
