import i18n from '@/i18n';

const DATE_PREVIOUS = [
    {
        label: 'CP開始日',
        value: 1
    },
    {
        label: 'CP終了日',
        value: 2
    },
    {
        label: '発送日',
        value: 3
    },
    {
        label: '事務局終了日',
        value: 4
    }
];

const FEEDBACK = [
    {
        label: 'F列',
        value: 1
    },
    {
        label: 'G列',
        value: 2
    },
    {
        label: 'H列',
        value: 3
    },
    {
        label: 'I列',
        value: 4
    },
    {
        label: 'J列',
        value: 5
    },
    {
        label: 'K列',
        value: 6
    }
];

const OPTIONS_TYPE = [
    {
        label: i18n.t('sheduleFile.isCommon'),
        value: 1
    },
    {
        label: i18n.t('sheduleFile.isPlatform'),
        value: 2
    }
];

export { DATE_PREVIOUS, FEEDBACK, OPTIONS_TYPE };
